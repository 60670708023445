<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="slider.edit.title" v-if="isEditing"></app-i18n>
            <app-i18n code="slider.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              class="flex justify-center"
              style="width: 100%; height: 80vh"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <form v-if="model">
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('slider.fields.image') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing && imageEnUrl">
                    <img
                      :src="imageEnUrl"
                      alt=""
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.imageEn = undefined"
                    />
                  </div>
                  <Uploader
                    type="image/*"
                    :maxSize="5"
                    ref="uploaderEn"
                    v-else
                  ></Uploader>
                </div>
                <div
                  class="lg:col-span-4 col-span-12 lg:mt-0 mt-2"
                  :class="isRTL ? 'lg:pr-5' : 'lg:pl-5'"
                >
                  <div class="relative" v-if="isEditing && imageArUrl">
                    <img
                      :src="imageArUrl"
                      alt=""
                      style="width: 100%; height: 150px"
                    />
                    <img
                      src="/images/deleteIcon.png"
                      alt=""
                      class="delete-icon w-12 h-12 absolute bottom-0 left-0 cursor-pointer"
                      @click="model.imageAr = undefined"
                    />
                  </div>
                  <Uploader
                    type="image/*"
                    :maxSize="5"
                    ref="uploaderAr"
                    v-else
                  ></Uploader>
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('slider.fields.sliderType') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <TomSelect
                    v-model="model.linkAction"
                    class="w-full mt-2 sm:mt-0"
                  >
                    <option value="webview">
                      {{ i18n('slider.fields.webview') }}
                    </option>
                    <option value="redirect">
                      {{ i18n('slider.fields.redirect') }}
                    </option>
                  </TomSelect>
                </div>
              </div>
              <div
                class="grid grid-cols-12 mt-5 items-center"
                v-if="sliderType === 'webview'"
              >
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('slider.fields.link') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="link"
                    type="text"
                    class="form-control w-full"
                    v-model="model.link"
                    :placeholder="i18n('slider.fields.link')"
                  />
                </div>
              </div>
              <div
                class="grid grid-cols-12 mt-5 items-center"
                v-if="sliderType === 'redirect'"
              >
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('slider.fields.redirect_item_type') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <TomSelect
                    v-model="model.redirect_item_type"
                    class="w-full mt-2 sm:mt-0"
                  >
                    <option
                      v-for="(option, index) in itemIdOptions"
                      :key="index"
                      :value="option.value"
                    >
                      <app-i18n :code="option.label" />
                    </option>
                  </TomSelect>
                </div>
              </div>
              <div
                class="grid grid-cols-12 mt-5 items-center"
                v-if="sliderType === 'redirect'"
              >
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('slider.fields.redirect_item_id') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="redirect_item_id"
                    type="text"
                    class="form-control w-full"
                    v-model="model.redirect_item_id"
                    :placeholder="i18n('slider.fields.redirect_item_id')"
                  />
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading || uploadLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-32 text-black w-24 ml-3 mr-3 cursor-pointer"
                  :disabled="saveLoading || findLoading || uploadLoading"
                  @click="doCancel"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { SliderModel } from '@/store/slider/slider-model'

const { fields } = SliderModel
const formSchema = new FormSchema([
  fields.id,
  fields.imageEn,
  fields.imageAr,
  fields.link,
  fields.linkAction,
  fields.redirect_item_id,
  fields.redirect_item_type
])

export default {
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      errorMessage: null,
      sliderType: null,
      itemIdOptions: [
        {
          value: 'staticCollection',
          label: 'slider.fields.staticCollection'
        },
        {
          value: 'staticOccasion',
          label: 'slider.fields.staticOccasion'
        },
        {
          value: 'collection',
          label: 'slider.fields.collection'
        },
        {
          value: 'product',
          label: 'slider.fields.product'
        },
        {
          value: 'blog',
          label: 'slider.fields.blog'
        },
        {
          value: 'article',
          label: 'slider.fields.article'
        },
        {
          value: 'page',
          label: 'slider.fields.page'
        },
        {
          value: 'webview',
          label: 'slider.fields.webview'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'slider/form/record',
      findLoading: 'slider/form/findLoading',
      saveLoading: 'slider/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    },
    filesToSaveEn() {
      return this.$refs.uploaderEn?.filesToSave || []
    },
    filesToSaveAr() {
      return this.$refs.uploaderAr?.filesToSave || []
    },
    imageEnUrl() {
      return this.model && this.model.imageEn
        ? this.model.imageEn.publicUrl
        : null
    },
    imageArUrl() {
      return this.model && this.model.imageAr
        ? this.model.imageAr.publicUrl
        : null
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.slider')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.slider')
    },
    'model.linkAction': {
      handler: function (newItemId, oldItemId) {
        if (newItemId !== oldItemId) {
          this.sliderType = newItemId
        }
        if (this.model.linkAction === 'webview') {
          this.model.redirect_item_id = ''
          this.model.redirect_item_type = ''
        }
        if (this.model.linkAction === 'redirect') {
          this.model.link = ''
        }
      },
      deep: true
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.model = formSchema.initialValues(this.record || {})
    this.model.linkAction = this.isEditing ? this.record.linkAction : 'webview'
    this.sliderType = this.isEditing ? this.record.linkAction : 'webview'
  },
  methods: {
    ...mapActions({
      doNew: 'slider/form/doNew',
      doFind: 'slider/form/doFind',
      doCreate: 'slider/form/doCreate',
      doUpdate: 'slider/form/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.linkAction.length === 0) {
        this.errorMessage = 'slider.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.linkAction === 'webview' &&
        (!this.model.link || this.model.link.length === 0)
      ) {
        this.errorMessage = 'slider.edit.errors.emptyFields'
        return false
      }
      if (
        this.model.linkAction === 'redirect' &&
        (!this.model.redirect_item_type ||
          this.model.redirect_item_type.length === 0 ||
          !this.model.redirect_item_id ||
          this.model.redirect_item_id.length === 0)
      ) {
        this.errorMessage = 'slider.edit.errors.emptyFields'
        return false
      }
      if (this.isEditing) {
        if (!(this.model.imageAr || this.filesToSaveAr.length !== 0)) {
          this.errorMessage = 'slider.edit.errors.emptyFields'
          return false
        }
        if (!(this.model.imageEn || this.filesToSaveEn.length !== 0)) {
          this.errorMessage = 'slider.edit.errors.emptyFields'
          return false
        }
      } else {
        if (
          this.filesToSaveEn.length === 0 ||
          this.filesToSaveAr.length === 0
        ) {
          this.errorMessage = 'slider.edit.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/slider')
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }

      const { id, ...values } = formSchema.cast(this.model)
      values.redirect_item_id = values.redirect_item_id || null
      values.redirect_item_type = values.redirect_item_type || null
      values.link = values.link || null
      if (this.isEditing) {
        await this.doUpdate({
          id,
          values,
          imageEn: this.imageEnUrl ? this.model.imageEn : this.filesToSaveEn[0],
          imageAr: this.imageArUrl ? this.model.imageAr : this.filesToSaveAr[0],
          newArImage: !this.imageArUrl,
          newEnImage: !this.imageEnUrl
        })
      } else {
        await this.doCreate({
          values,
          imageEn: this.filesToSaveEn[0],
          imageAr: this.filesToSaveAr[0]
        })
      }
    }
  }
}
</script>
